@import "./variables.scss";

.inputGroup {
  &--selectHorizontal {
    width: 100%;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 200px;
    gap: 5px;
    align-items: center;
    margin-bottom: 5px;
  }
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  display: flex;
  align-items: center;
  height: 30px;
  width: 100%;
  padding: 0px 28px 4px 10px;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.5;
  text-indent: 0;
  color: white;
  background-color: rgba(white, 0.1);
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7.2'%3E%3Cpath style='fill:%23ffffff' d='M0,1.6,6,7.2l6-5.6L10.4,0,6,4,1.6,0Z'/%3E%3C/svg%3E");
  background-position: right 12px top 13px;
  background-size: 7px auto;
  background-repeat: no-repeat;

  &:focus {
    outline: none;
  }

  &:disabled {
    opacity: 0.3;
    cursor: default;
  }

  option {
    color: black;
  }
}

.input {
  background: none;
  color: white;
  border: none;
  outline: none;
  border-bottom: 1px solid $borderColor;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-size: 12px;
}
