.apotome {
  .toggleSwitch {
    display: inline-block;
    margin-right: 5px;
    margin-top: 2px;

    &.isDisabled {
      pointer-events: none;
      opacity: 0.3;
    }

    &Label {
      display: flex;
      flex-flow: row;
      align-items: center;
    }
  }
}
