.newHome {
  background: #f7f7f7;
  color: #222;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;

  .main {
    width: 100%;
    max-width: 700px;
    height: auto;
    display: grid;
    grid-template-areas:
      "leimma"
      "apotome"
      "ctm"
      "credits";
    grid-template-columns: 1fr;
    grid-template-rows: auto auto auto auto;
    gap: 10px;
    margin: 10px;

    @media (min-width: 768px) {
      grid-template-areas:
        "leimma apotome"
        "ctm ctm"
        "credits credits";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
    }

    .leimma,
    .apotome {
      display: flex;
      flex-flow: column nowrap;
      align-items: flex-start;
      padding: 20px 30px 30px 30px;
      background: #8870ff;
      // border-radius: 10px;
      // box-shadow: 0 0 20px rgba(darkblue, 0.3);
      text-decoration: none;

      .logo {
        display: block;
        max-width: 100%;
        filter: drop-shadow(2px 2px 1px rgba(black, 0.5));
        margin: 0;
        height: 50px;

        @media (min-width: 768px) {
          height: 60px;
        }
      }

      p {
        max-width: 280px;
        color: white;
        margin: 0;
      }

      .fauxButton {
        background: transparent;
        border: 2px solid rgba(white, 0.5);
        color: white;
      }
    }

    .leimma {
      grid-area: leimma;
    }

    .apotome {
      grid-area: apotome;
    }

    .ctm {
      grid-area: ctm;

      display: flex;
      flex-flow: column nowrap;
      padding: 30px;
      background: #00e8be;
      // border-radius: 10px;
      // box-shadow: 0 0 20px rgba(darkblue, 0.3);

      p {
        max-width: 380px;
        color: black;
        margin: 0;
      }
    }

    .credits {
      grid-area: credits;
      padding: 10px 30px;

      p {
        max-width: 500px;
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 6px;
        color: #777;

        a {
          text-decoration: underline;
          color: #777;
        }
      }
    }

    .logoWrapper {
      width: 100%;
      border-bottom: 2px solid rgba(white, 0.25);
      margin: 0 0 15px 0;
      padding: 0 0 7px 0;
    }

    .fauxButton {
      height: 36px;
      min-width: 100px;
      border-radius: 36px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      background: none;
      border: none;
      padding: 0 15px 3px 15px;
      text-decoration: none;
      cursor: pointer;
      background: black;
      color: white;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-size: 14px;
      margin-top: 15px;

      background: transparent;
      border: 2px solid rgba(black, 0.5);
      color: black;

      margin-right: 10px;

      &:active,
      &:focus {
        outline: none;
      }
    }

    h1 {
      font-size: 28px;
      font-weight: 400;
      line-height: 1;
      margin: 0 0 1rem 0;
    }

    h2 {
      font-size: 22px;
      font-weight: 500;
      margin: 0 0 0.5rem 0;
    }

    p {
      font-size: 15px;
      margin: 0 0 1rem 0;
      color: #222;

      a {
        text-decoration: underline;
        color: #222;
      }
    }
  }
}
