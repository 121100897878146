.calibrateMIDIOutputDialogWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.7);

  z-index: 3;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  &.isOpen {
    pointer-events: initial;
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .calibrateMIDIOutputDialog {
    width: 600px;
    height: 350px;
    position: relative;
    background-color: #333;
    border-radius: 3px;
    font-size: 1rem;

    padding: 20px;

    p {
      line-height: 1.2;
    }
    .testLooper {
      display: flex;
      align-items: center;

      &--indicators {
        margin-left: 10px;
        display: none;
        &.isVisible {
          display: flex;
        }
      }
      &--point {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 1px solid #888;

        background-color: white;
        &.on {
          background-color: black;
        }
      }
    }
    .pitchInput {
      margin-top: 20px;
    }
    input[type="range"] {
      width: 200px;
    }
    .numberInput {
      width: 50px;
      margin-left: 10px;
    }

    &--actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        padding: 0 20px;
      }
    }
  }
}
