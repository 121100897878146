.refPitchInput {
  display: flex;
  flex-direction: row;

  .freqNumberInput {
    display: inline-block;
    width: 135px;
    position: relative;
    padding: 0;
    margin: 0;
    font-size: 32px;
    line-height: 1;
    font-weight: 400;
    background: none;
    border: none;
    color: white;
    text-align: left;
    border-bottom: 2px solid rgba(#aaa, 0.5);

    &:focus {
      outline: none;
    }
  }

  .hzLabel {
    position: relative;
    font-size: 20px;
    line-height: 1;
    font-weight: 400;
    color: white;
    z-index: 1;
    padding: 8px 0;
    user-select: none;
  }

  .refPitchNote {
    margin-right: 10px;
    padding-right: 40px;
    border: none !important;
    width: 70px;
  }

  &.small {
    .freqNumberInput {
      width: 75px;
      font-size: 16px;
    }

    .refPitchNote,
    .hzLabel {
      font-size: 14px;
      font-weight: 500;
      line-height: 1;
    }

    .hzLabel {
      font-size: 12px;
      margin-top: 4px;
    }
  }

  &.tiny {
    .freqNumberInput {
      width: 53px;
      font-size: 12px;
    }

    .refPitchNote,
    .hzLabel {
      font-size: 12px;
      line-height: 12px;
    }
    .refPitchNote {
      padding-right: 10px;
    }
  }
}
