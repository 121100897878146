.knobControl {
  display: flex;
  flex-direction: column;
  align-items: center;

  &.isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &--label,
  &--valueLabel {
    font-size: 10px;
    text-align: center;
  }
  &--label {
    margin-bottom: 5px;
  }
  &--valueLabel {
    margin-top: 5px;
  }
}
