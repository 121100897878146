@import "../variables.scss";

.sessionList {
  h2 {
    margin-top: 0;
  }

  h4 {
    button {
      float: right;
    }
  }

  &Tabs {
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
    margin-bottom: 5px;

    .button--sessionListTab {
      border-radius: 0;
      background: none;
      height: 30px;
      font-size: 11px;
      color: white;

      // border-bottom: 2px solid rgba(white, 0);

      &.isActive {
        // border-color: rgba(white, 0.2);
        background: #090909;
      }
    }
  }

  &--notAuthenticated {
    padding: 10px;
  }

  &--sessionListItem {
    position: relative;
    padding: 15px;
    background-color: #090909;
    border-radius: 5px;
    margin: 0 0 15px 0;
  }

  &--sessionName {
    margin: 0 0 5px 0;
    font-size: 12px;
    font-weight: 500;
  }

  &--sessionDescription {
    font-size: 12px;
    color: rgba(white, 0.75);
  }
  &--sessionAuthor {
    font-size: 12px;
    margin-top: 5px;
    color: rgba(white, 0.75);
  }

  &--sessionActions {
    margin-top: 10px;
    button {
      margin-right: 5px;
    }
  }

  &--sessionSharing {
    margin-top: 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    .sessionList--sessionStatus,
    button {
      margin-right: 10px;
    }
  }

  &--newSessionButton {
    display: block;
    width: 100%;
    margin: 10px 0;
  }
}
