@import "./typography.scss";

html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overscroll-behavior: none;
  overflow: hidden;
}

body {
  background: #222;
}

.mainApp,
.screen {
  .inputGroup {
    z-index: 2;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    label {
      white-space: nowrap;
      margin-right: 10px;
      font-size: 13px;

      &:not(:first-child) {
        margin-left: 20px;
      }
    }

    &.secondary {
      font-size: 0.8em;
    }
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: relative;
    display: flex;
    align-items: center;
    height: 42px;
    width: 100%;
    padding: 0px 32px 2px 12px;
    background: rgba(white, 0.1)
      url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7.2'%3E%3Cpath style='fill:%23ffffff' d='M0,1.6,6,7.2l6-5.6L10.4,0,6,4,1.6,0Z'/%3E%3C/svg%3E")
      right 13px top 12px / 8px 10px no-repeat;
    border: 1px solid rgba(black, 0);
    border-radius: 3px;
    cursor: pointer;
    font-size: 16px;
    font-family: urw-din, sans-serif;
    font-weight: 500;
    line-height: 1.5;
    text-indent: 0;
    transition: background-color 0.15s ease;
    color: white;
    box-shadow: none;

    font-size: 13px;
    height: 36px;

    option {
      color: hsla(254, 81.5, 12.7, 1);
    }

    &:focus {
      outline: none;
    }

    &:hover {
      background-color: rgba(white, 0.15);
    }

    &.small {
      height: 21px;
      font-size: 12px;
      line-height: 1;
      border-radius: 2px;
      padding: 0px 32px 0 6px;
      background: rgba(white, 0.1)
        url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 7.2'%3E%3Cpath style='fill:%23ffffff' d='M0,1.6,6,7.2l6-5.6L10.4,0,6,4,1.6,0Z'/%3E%3C/svg%3E")
        right 13px top 4px / 10px 12px no-repeat;
    }
  }

  input {
    font-family: urw-din, sans-serif;
    font-weight: 500;
    line-height: 1;
  }

  input[type="number"],
  .input--number {
    height: 42px;
    width: 100%;
    padding: 12px;
    font-family: urw-din, sans-serif;
    font-weight: 500;
    font-size: 16px;
    line-height: 1.5;
    color: black;
    background: transparent;
    border-bottom: 1px solid rgba(#aaa, 0.5);
    border-radius: 3px;
    box-shadow: none;

    &:focus {
      outline: none;
    }

    &.small {
      height: 21px;
      font-size: 12px;
      line-height: 1;
      padding: 5px;
    }
  }

  .termDefinitionLink {
    position: relative;
    border-bottom: 2px solid rgba(#aaa, 0.5);
    user-select: none;

    &:after {
      content: "?";
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      z-index: 3;
      top: -10px;
      right: -25px;
      width: 21px;
      height: 21px;
      border-radius: 50%;
      font-size: 15px;
      line-height: 1;
      font-weight: 600;
      background: #aaa;
      color: white;
      text-align: center;
      cursor: pointer;
    }
  }
}

.button {
  height: 40px;
  background: black;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  line-height: 40px;
  border-radius: 2px;
  border: none;
  box-shadow: 1px 1px 15px 0 rgba(#aaa, 1);
  outline: none;

  &.small {
    font-size: 13px;
    height: auto;
    line-height: 160%;
  }
  &[disabled] {
    background: gray;
    color: lightgray;
  }
}
.nextScreenButton {
  @extend .button;

  z-index: 2;
  display: block;
  width: 150px;

  position: fixed;
  bottom: 10vh;
  transform: translateY(100%);
}

.Layout-root-1 {
  // Admin UI
  height: 100vh;
  overflow: auto;
}
