@import "./variables.scss";

.tempoVisualizer {
  width: 34px;
  height: 15px;
  margin: 0 5px;
  position: relative;

  &--point {
    position: absolute;
    width: 15px;
    height: 15px;

    &:first-child {
      left: 0;
    }

    &:last-child {
      right: 0;
    }

    border-radius: 50%;

    background-color: $secondaryElementBackgroundColor;
    &.on {
      background-color: #bde1f4;
    }
  }
}
