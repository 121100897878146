.scaleScreen {
  position: absolute;
  width: 100%;
  height: 100%;

  &--tuningSystemInfo {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 0;

    h1 {
      margin-bottom: 10px;
    }

    .fieldLabel {
      color: rgba(white, 0.75);
    }

    .introText {
      max-width: 400px;

      font-size: 13px;
      color: rgba(white, 0.75);
      margin-bottom: 5px;
      a,
      a:visited,
      a:active {
        color: inherit;
      }

      &.source {
        font-size: 11px;
        color: rgba(white, 0.5);
      }
    }
  }

  .tuningSystemDialogToggle {
    position: fixed;
    right: 40px;
    top: 205px;
  }
  .centsRatiosSwitch {
    position: fixed;
    right: 40px;
    top: 240px;
  }
  .clearMappingsButton {
    position: fixed;
    right: 40px;
    top: 275px;
  }
  .databaseEntryButton {
    position: fixed;
    right: 40px;
    top: 310px;
  }

  &--canvas,
  &--htmlOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--canvas {
    z-index: 1; // Needed to make the wheel clickable; this should change when it's moved to the Flatlander
  }

  &--htmlOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .hint {
      max-width: 200px;
      text-align: center;
    }

    .inputGroup {
      margin: 10px 0;
    }

    .scaleDegreeRoleSelection {
      width: auto;
    }
    .centsRatioInput input {
      width: 50px;
      box-shadow: none;
      text-align: center;
      color: white;
      border: none;
      border-bottom: 2px solid rgba(#aaa, 0.5);
      border-radius: 0;
    }
    .inputWrapper,
    .scalePicker,
    .centsRatiosSwitch,
    .tuningSystemDialogToggle,
    .clearMappingsButton,
    .databaseEntryButton,
    .solmizationInput,
    .existingScaleInput,
    .scaleScreen--tuningSystemInfo {
      z-index: 2;
    }
  }

  &--htmlOverlay,
  .userInfo,
  .leimma--userGuideLink {
    opacity: 0;
    transition: opacity 0.5s;
  }
  &.hasEntered {
    .scaleScreen--htmlOverlay,
    .userInfo,
    .leimma--userGuideLink {
      opacity: 1;
    }
  }
}
