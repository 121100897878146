.userInfo {
  position: fixed;
  bottom: 40px;
  right: 40px;

  z-index: 4;

  display: flex;
  align-items: center;
  padding: 5px;
  font-size: 13px;
  color: rgba(white, 0.75);

  button {
    font-size: 14px;
    margin-left: 5px;
    height: auto;
    line-height: 160%;
  }
}
