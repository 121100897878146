.leimma--userGuideLink {
  position: fixed;
  top: 40px;
  right: 45px;
  font-size: 12px;
  z-index: 3;
  a {
    display: inline-block;
    color: white;
    &:not(:last-child) {
      margin-right: 7px;
    }
  }
}
