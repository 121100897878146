@import "./variables.scss";

.apotome {
  h4.useAccentToggle {
    display: block;
    margin-top: 5px;
    display: flex;
    align-items: center;
  }
  .useAccentToggle--label {
    &.isActive {
      background-color: $textHighlightColor;
    }
  }
}
