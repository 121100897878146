.tuningSnapshotsContainer {
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  width: 300px;
  background-color: #eee;
  z-index: 5;

  transform: translateX(100%);
  transition: transform 0.15s ease;
  &.isOpen {
    transform: translateX(0);
  }

  .tuningSnapshotsCallout {
    position: absolute;
    top: 50%;
    left: 0;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 130px 20px;
    transform: translate(-100%, -200%) rotate(-90deg);

    background-color: #eee;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-family: urw-din, sans-serif;
    font-weight: 600;
    outline: none;
    cursor: pointer;
  }

  .tuningSnapshotsManager {
    position: absolute;
    width: 100%;
    height: 100%;
    border-left: 1px solid rgba(white, 0.1);
    display: block;

    color: black;

    &--content {
      padding-top: 0;
    }

    &--snapshot {
      background-color: #ddd;
      &.isCurrent {
        outline: 1px solid black;
      }
    }

    .input {
      color: black;
      border-bottom: 1px solid black;
    }
  }
}
