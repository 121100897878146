@import "./variables.scss";

.returnTrack {
  width: $returnTrackWidth;
  padding: 10px;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  background-color: #111;
  border-left: 1px solid rgba(white, 0.1);

  &Group {
    background: #090909;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
  }

  h4 {
    text-align: center;
    margin: 0;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: #ddd;
  }

  &--reverbControls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
  }

  &--echoControls {
    display: flex;
    flex-direction: column;
    font-size: 10px;
    margin-top: 10px;

    &--row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 10px;
    }
    &--channel {
      flex: 1;
      display: flex;
    }
  }
}
