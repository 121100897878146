.octaveDivisionScreen {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;

  &--tuningSystemInfo {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 0;

    h1 {
      margin-bottom: 10px;
    }

    .fieldLabel {
      color: rgba(white, 0.75);
    }
    .introText {
      max-width: 400px;

      font-size: 13px;
      color: rgba(white, 0.75);
      margin-bottom: 5px;
      a,
      a:visited,
      a:active {
        color: inherit;
      }

      &.source {
        font-size: 11px;
        color: rgba(white, 0.5);
      }
    }
  }

  .tuningSystemDialogToggle {
    position: fixed;
    top: 140px;
    right: 40px;
    z-index: 2;
  }
  .databaseEntryDialogToggle {
    position: fixed;
    top: 175px;
    right: 40px;
    z-index: 2;
  }
  .viewSelector {
    position: fixed;
    top: 150px;
    right: 40px;
    z-index: 2;
  }

  &--canvas,
  &--htmlOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--htmlOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    pointer-events: none;

    .octaveDivisionScreen--tuningSystemInfo,
    .midiOutput,
    .tuningSystemDialogToggle,
    .databaseEntryDialogToggle,
    .refPitchInput,
    .viewSelector {
      z-index: 2;
      pointer-events: initial;
    }
  }

  &--canvas {
    pointer-events: none;
    .frequencyBand {
      pointer-events: visible;
    }
  }

  h1,
  .introText,
  .tuningSystemDialogToggle,
  .databaseEntryDialogToggle,
  .viewSelector,
  .octaveDivisionOctaveView,
  .refPitchInput,
  .midiOutput,
  .scalaImport,
  .scalaExport.button,
  .breadcrumbs,
  .userInfo,
  .leimma--userGuideLink,
  .pitchInput,
  .octaveDivisionBand--divisionLabel {
    opacity: 0;
    transition: opacity 0.5s ease;
  }
  .frequencyBand {
    opacity: 1;
    transition: opacity 0.5s ease;
  }
  &.hasEntered {
    .octaveDivisionOctaveView {
      opacity: 1;
    }
  }
  &.hasEntered:not(.isExiting) {
    h1,
    .introText,
    .tuningSystemDialogToggle,
    .databaseEntryDialogToggle,
    .viewSelector,
    .refPitchInput,
    .midiOutput,
    .scalaImport,
    .scalaExport.button,
    .breadcrumbs,
    .userInfo,
    .leimma--userGuideLink,
    .pitchInput,
    .octaveDivisionBand--divisionLabel {
      opacity: 1;
    }
    .viewSelector {
      transition-delay: 100ms;
    }
    .midiOutput,
    .scalaImport,
    .scalaExport,
    .databaseEntryDialogToggle,
    .tuningSystemDialogToggle,
    .userInfo {
      transition-delay: 200ms;
    }
  }
  &.isExiting {
    .frequencyBand {
      opacity: 0;
    }
  }
}
