.scalaExport {
  position: fixed;
  right: 40px;
  top: 120px;
  z-index: 2;
  pointer-events: all;

  &.withMidiInput {
    top: 170px;
  }
}

.scalaExportChoiceDialogWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.7);

  z-index: 3;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  &.isOpen {
    pointer-events: initial;
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .scalaExportChoiceDialog {
    width: 400px;
    height: 160px;
    position: relative;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;

    padding: 0 30px;

    &--options {
      display: flex;
      flex-direction: column;
      button {
        margin: 5px 0;
      }
    }
  }
}
