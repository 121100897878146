.midiInput {
  position: fixed;
  right: 40px;
  top: 60px;
  z-index: 2;

  .inputGroup {
    label {
      font-size: 12px;
      white-space: nowrap;
      margin-right: 10px;
    }

    select {
      width: 130px;
      font-size: 12px;
      height: 36px;
      background-position: right 13px top 11px;
    }
  }

  a,
  a:active,
  a:visited {
    color: white;
  }

  .notSupportedError {
    font-size: 14px;
  }
}
