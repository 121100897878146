@import "../variables.scss";

.sessionPlayer {
  h2 {
    padding: 10px;
    margin-bottom: 0;
  }
  h3 {
    padding: 0 10px;
  }
  .backToMain {
    margin-right: 5px;
  }

  &--author,
  &--description,
  &--playbackActions {
    padding: 5px 10px;
    font-size: 14px;
  }
  &--description,
  &--playbackActions {
    margin-bottom: 10px;
  }

  &--playbackActions {
    display: flex;
    align-items: center;
    button {
      margin-right: 10px;
    }
  }

  &--section {
    padding: 15px;
    margin-bottom: 5px;
    background-color: #090909;
    border-radius: 5px;
    min-height: 100px;
    &.isPlaying {
      outline: 1px solid $borderColor;
    }
    h4 {
      margin: 0 0 10px 0;
    }
  }
  &--sectionTimer {
    margin-top: 10px;
  }
}
