.tuningSystemWheel {
  &--div {
    fill: black;
    stroke: #222;
    stroke-width: 2;
    cursor: pointer;

    &.isSelected {
      stroke: rgba(red, 1);
      stroke-width: 2;
    }
  }

  &--scaleBand {
    fill: black;
    stroke: #222;
    stroke-width: 2;
    cursor: pointer;

    &.mappingNone {
      fill: rgba(white, 0.1) !important;
    }
    &.mappingSecondary {
      fill: #555e62 !important;
    }

    &.mappingPrimary {
      fill: #788a94 !important;
    }
    &.mappingTonic {
      fill: #9bb6c4 !important;
    }
    &.isHighlighted {
      fill: red !important;
    }

    &.isSelected {
      fill: red !important;
      stroke: rgba(red, 1);
      stroke-width: 2;
    }
  }
  &--scaleBandGuide,
  &--scaleLabelGuide {
    opacity: 0.2;
    .tuningSystemWheel--scaleBand {
      opacity: 0;
    }
  }

  &--midiMappingBand {
    fill: white;
    &.isAccidental {
      fill: black;
    }
  }

  &--solmizationLabel {
    font-size: 24px;
    font-weight: 400;
    pointer-events: none;
    fill: white;

    &.isHighlighted {
      fill: white;
    }

    // &.solmizationLabelPrimary,
    // &.solmizationLabelSecondary {
    //   fill: #222 !important;
    // }
  }

  &--divisionLabel {
    font-size: 12px;
    font-weight: 600;
    pointer-events: none;
    fill: white;
  }

  &--midiMappingLabel {
    fill: white;
    font-size: 13px;
    font-weight: 500;
    pointer-events: none;
  }

  &--tonicMarkerLine {
    stroke: white;
    stroke-width: 3;
  }
  &--tonicMarkerHandle {
    fill: white;
    stroke-width: 3;
    cursor: grab;
  }
  &--tonicMarkerPlayIcon {
    fill: white;
    cursor: pointer;
  }

  &--tonicMarkerDragGuideLine {
    stroke: rgba(black, 0.1);
    stroke-width: 3;
  }
  &--tonicMarkerDragGuideHandle {
    fill: rgba(black, 0.1);
    stroke-width: 3;
  }
}
