@import url("https://use.typekit.net/ugu1iba.css");
@import "./variables.scss";

.apotome {
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-size: 12px;
  color: white;

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
    text-transform: uppercase;
  }
}
