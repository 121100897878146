.octaveDivisionOctaveView {
  .octaveDivisionScreen--htmlOverlay {
    .refPitchInput {
      z-index: 2;
      pointer-events: initial;
    }
    .divisionValueInput {
      position: fixed;
      left: 0;
      top: 0;
      transform-origin: center;
      z-index: 2;
      pointer-events: initial;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 260px;

      &.withDelete {
        width: 380px;
      }
      height: 42px;
      line-height: 42px;

      input {
        width: 100px;
        box-shadow: none;
        text-align: center;
        color: white;
        border: none;
        border-bottom: 2px solid rgba(#aaa, 0.5);
        border-radius: 0;
      }
    }
  }

  .svgCanvas {
    .selectedDivisionMarker {
      stroke: white;
      stroke-width: 2;
    }
    .mouseMarkerLine {
      stroke: white;
      stroke-width: 1;
      pointer-events: none;
    }
    .mouseMarkerLineLabel {
      font-size: 12px;
      font-weight: 500;
      fill: white;
    }
  }
}
