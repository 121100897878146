@import "../variables.scss";

.sessionManager {
  position: relative;
  width: $sessionManagerWidth;
  overflow-y: auto;
  background-color: #111;
  border-left: 1px solid rgba(white, 0.1);

  display: none;
  .sessionManagerOpen & {
    display: block;
  }

  .closeButton {
    position: absolute;
    top: 25px;
    right: 15px;
  }

  &--content {
    padding: 15px;

    h2 {
      font-size: 18px;
      margin-top: 10px;
    }
  }

  &--sessionList {
    margin: 30px 0;
    h4 button {
      float: right;
    }
  }

  &--sessionListItem {
    position: relative;
    padding: 10px 2px;
    cursor: pointer;
    border: 1px solid rgba(white, 0);
    &.isCurrent {
      border: 1px solid white;
    }
  }

  &--sessionName {
    max-width: 200px;
  }
  &--sessionAuthor {
    max-width: 200px;
    font-size: 12px;
    margin: 2px 0 0 10px;
  }

  &--updateSessionStatus {
    position: absolute;
    right: 20px;
    top: 7px;
  }
  &--deleteSession {
    position: absolute;
    right: 0;
    top: 7px;
  }
}
