.apotome {
  .envelopeControl {
    position: relative;
    margin: 15px 0;
    width: 100%;

    &--envelope {
      width: 100%;
    }

    &--label {
      font-size: 10px;
      position: absolute;
      bottom: 5px;
      left: 0;
      width: 100%;
      text-align: center;
      pointer-events: none;
    }
  }
}
