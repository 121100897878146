@import "./variables.scss";

.apotome {
  .notSupportedWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.7);

    z-index: 3;

    display: flex;
    align-items: center;
    justify-content: center;

    .notSupported {
      font-size: 1.5rem;
      text-align: center;
    }
  }

  @media only screen and (max-width: 1024px) {
    .notSupportedWrapper {
      background-color: rgba(black, 1.0);
      .notSupported {
        font-size: 1.0rem;
        margin: 0 10px;
      }
    }

  }
}
