@import "./variables.scss";

$railSize: 5px;
$handleSize: 14px;

.rangeSlider {
  width: calc(100% - #{$handleSize});
  height: $handleSize;
  position: relative;

  &.isDisabled {
    opacity: 0.3;
  }
  &--rail {
    position: absolute;
    left: 0;
    top: ($handleSize - $railSize) / 2;
    width: calc(100% + #{$handleSize});
    height: $railSize;
    border-radius: $handleSize / 2;
    background-color: #333;
  }

  &--handle {
    position: absolute;
    top: 0;
    width: $handleSize;
    height: $handleSize;
    border-radius: $handleSize / 2;
    background-color: #bde1f4;
  }

  &--activeRange {
    position: absolute;
    top: ($handleSize - $railSize) / 2;
    height: $railSize;
    background-color: rgba(#bde1f4, 0.5);
  }

  &--currentValueMarker {
    position: absolute;
    top: 0;
    height: $handleSize;
    width: 2px;
    border-radius: 2px;
    background-color: #bde1f4;
    opacity: 0;
    &.isVisible {
      opacity: 1;
    }
  }
}
