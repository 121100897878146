.refPitchScreen {
  position: absolute;
  width: 100%;
  height: 100%;

  h1 {
    position: fixed;
    top: 40px;
    left: 40px;
    z-index: 0;
  }
  .introText {
    position: fixed;
    top: 80px;
    left: 40px;
    max-width: 400px;
    z-index: 0;
  }

  &--canvas,
  &--htmlOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  &--htmlOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .inputWrapper {
      z-index: 2;
      position: relative;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch;
      justify-content: stretch;
      transform: translateY(130px);
      background: transparent;
      padding: 8px 15px 12px 15px;
      border-radius: 5px;

      &Arrow {
        position: absolute;
        display: block;
        height: 7px;
        width: auto;
        top: -7px;
        left: 50%;
        transform: translateX(-50%);

        polygon {
          fill: none;
        }
      }
    }
  }

  &--refPitchLine {
    fill: none;
    stroke: red;
    stroke-width: 3px;
    pointer-events: none;
  }

  h1,
  .introText,
  .refPitchInput,
  .midiOutput,
  .breadcrumbs,
  .leimma--userGuideLink,
  .userInfo {
    opacity: 0;
    transition: opacity 0.5s;
  }
  &.hasEntered:not(.isExiting) {
    h1,
    .introText,
    .refPitchInput,
    .midiOutput,
    .breadcrumbs,
    .leimma--userGuideLink,
    .userInfo {
      opacity: 1;
    }
  }
}
