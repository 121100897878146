.midiOutput {
  &.fixed {
    position: fixed;
    right: 40px;
    top: 70px;
    z-index: 3 !important;

    &.withInput {
      top: 110px;
    }

    .inputGroup {
      label {
        font-size: 12px;
        white-space: nowrap;
        margin-right: 10px;
      }

      select {
        font-size: 12px;
        height: 36px;
        background-position: right 13px top 11px;
        &.midiOutput--device {
          width: 170px;
        }
        &.midiOutput--channel {
          width: 100px;
        }
      }
    }
  }

  a,
  a:active,
  a:visited {
    color: white;
  }

  .notSupportedError {
    font-size: 14px;
  }

  &.withInput .notSupportedError {
    display: none;
  }
}
