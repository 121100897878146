.newOrExistingChoiceScreen {
  position: absolute;
  width: 100%;
  height: 100%;

  &--canvas,
  &--htmlOverlay {
    position: absolute;
    width: 100%;
    height: 100%;
  }

  .leimmaLogo {
    height: 120px;
    max-width: 100%;
    filter: drop-shadow(4px 4px 8px rgba(black, 0.9));
  }

  &--htmlOverlay {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .button {
      height: 36px;
      padding: 0 20px 2px 20px;
      font-size: 16px;
      font-weight: 500;
      margin: 10px;
      z-index: 2;
    }
  }

  a,
  a:visited,
  a:active {
    color: white;
    text-decoration: none;
  }

  &--credits,
  &--credits a {
    font-size: 12px;
    color: rgba(white, 0.75);
    margin: -5px 0 20px 0;
  }

  &--intro {
    font-size: 16px;
    margin: 0 0 20px 0;
  }

  &--htmlOverlay {
    opacity: 1;
    transition: opacity 0.5s;
  }
  &.isExiting {
    .newOrExistingChoiceScreen--htmlOverlay {
      opacity: 0;
    }
  }
}
