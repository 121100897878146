.frequencyBand {
  cursor: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 43 32'%3E%3Cpolygon points='16 0 0 16 16 32 16 0'/%3E%3Cpolygon points='27 0 43 16 27 32 27 0'/%3E%3C/svg%3E"),
    col-resize;

  &--band {
    fill: transparent;
  }

  &--lineHorizontal {
    stroke: hsla(0, 0, 60, 1);
    stroke-width: 2px;
  }

  &--majorTickLine {
    stroke: hsla(0, 0, 60, 1);
    stroke-width: 2px;
  }

  &--majorTickLabel {
    user-select: none;
    text-anchor: middle;
    font-size: 14px;
    font-weight: 600;
    fill: hsla(0, 0, 60, 1);
    cursor: pointer;

    &.isSelected {
      fill: white;
    }
  }

  &--minorTickLine {
    stroke: hsla(0, 0, 60, 1);
    stroke-width: 2px;
  }

  &--focusShape {
    pointer-events: none;
    fill: rgba(white, 0.05);
  }
}
