.breadcrumbs {
  position: fixed;
  bottom: 40px;
  left: 40px;
  z-index: 2;
  pointer-events: visible;

  display: flex;
  align-items: center;

  &--number {
    width: 50px;
    height: 50px;
    border-radius: 50px;
    font-size: 36px;
    line-height: 1;
    font-weight: 400;
    background: rgba(black, 1);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    user-select: none;

    &:not(:first-child) {
      margin-left: 5px;
    }

    &.previousStep {
      color: rgba(white, 0.5);
      background: rgba(white, 0.05);
      transition: background-color 0.15s ease, color 0.15s ease;
      text-decoration: none;

      &:hover {
        background: rgba(white, 0.1);
        color: white;
      }
    }
  }
  &--description {
    width: 400px;
    margin-left: 10px;
    font-size: 13px;
  }
  &--nextAction {
    height: 50px !important;
    width: 100px !important;
    border-radius: 50px;
    margin-left: 10px;
    padding: 0 20px;
    font-size: 18px !important;

    &.isDisabled {
      pointer-events: none;
      background-color: gray;
      color: lightgray;
      box-shadow: none;
    }
  }
}
