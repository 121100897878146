.apotome {
  .timeSignatureControl {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    max-width: 75px;

    input {
      margin-bottom: 2px;
      padding-right: 12px;
      padding-bottom: 4px;
    }
    select {
      border: none;
      height: 18px;
      background-color: transparent;
      background-position: right 12px top 7px;
      padding-left: 20px;
    }
    input,
    select {
      text-align: center;
      text-align-last: center;
      font-size: 12px;
    }
  }
}
