.screenSizeWarning {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  padding: 10px;

  font-size: 16px;
  text-align: center;

  z-index: 3;
  background-color: black;

  &.dismissed {
    display: none;
  }
  @media only screen and (min-width: 900px) {
    display: none;
  }
}
