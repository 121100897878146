.chooseTuningSystemDialogWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.7);

  z-index: 3;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  &.isOpen {
    pointer-events: initial;
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .chooseTuningSystemDialog {
    width: 1024px;
    height: 80vh;
    position: relative;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;

    padding: 30px;

    h2 {
      margin: 0 0 30px 0;
    }

    &--columns {
      display: flex;
      flex-direction: row;
      height: calc(80vh - 150px);
    }
    &--categoryColumn {
      flex: 1;
    }
    &--tuningSystemColumn {
      flex: 2;
    }
    &--column {
      overflow-y: auto;
      list-style: none;
      margin: 0 5px 0 0;
      padding: 0;

      li {
        padding: 7px 15px;
        border-radius: 3px;
        font-size: 13px;
        cursor: pointer;

        &.isSelected,
        &:hover {
          background-color: rgba(white, 0.1);
        }
      }
    }

    &--tuningSystemName {
      font-size: 13px;
      font-weight: 600;
      margin-bottom: 0;
    }
    &--tuningSystemDescription,
    &--tuningSystemSource {
      font-size: 13px;
      color: rgba(white, 0.75);
      margin-bottom: 5px;
      a,
      a:visited,
      a:active {
        color: inherit;
      }
    }

    &--tuningSystemSource {
      font-size: 11px;
      color: rgba(white, 0.5);
    }

    &--actions {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;

      padding: 20px;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      button {
        margin-left: 10px;
        padding: 0 20px;
      }
    }
  }
}
