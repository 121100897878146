@import "./variables.scss";

.apotome {
  .globalControls {
    z-index: 3;

    &--headerBar {
      height: 45px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      color: #111;
      background: url(./images/backgroundHeader.png) bottom left / 100% auto
        no-repeat;
      //box-shadow: 0 0 15px 0 rgba(#bde1f4, 0.75);

      h1 {
        font-size: 18px;
        font-weight: 500;
        margin: 0;
        line-height: 1;
        color: black;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-end;

        img {
          display: block;
          height: 50px;
          margin-bottom: 1px;
          filter: drop-shadow(2px 2px 1px rgba(black, 0.9));
        }

        .globalControls--currentSpace {
          font-size: 10px;
          margin-bottom: 11px;
          margin-left: 7px;
        }
      }

      .globalControls--bookingButton {
        position: absolute;
        top: 20px;
        left: 50%;
        transform: translate(-50%);
      }

      &--guide {
        margin-left: auto;
        margin-right: 20px;
        height: 20px;
        a,
        a:visited,
        a:active {
          display: inline-block;
          color: black;
          &:not(:last-child) {
            margin-right: 7px;
          }
        }
      }

      &--auth {
        .button {
          margin-left: 15px;
        }
      }
      &--userProfileName {
        display: inline-block;
        cursor: pointer;
        height: 20px;
      }
    }

    &--bar {
      padding: 5px 15px;
      background: #090909;
      border-bottom: 1px solid rgba(white, 0.1);
      box-shadow: 0 10px 10px 0 rgba(black, 0.5);

      display: flex;
      align-items: center;

      > * {
        margin-right: 20px;
      }

      .button--startStop {
        width: 80px;
      }
    }

    &--tempo,
    &--masterGain {
      display: flex;
      align-items: center;

      .slider {
        margin: 0 5px;
      }
      .numberInput {
        width: 40px;
      }
    }

    &--masterGain {
      width: 220px;
    }

    &--timeSignature {
      display: flex;
      align-items: center;
      .timeSignatureControl {
        margin-left: 5px;
      }
    }
  }
}
