.scalaImport {
  position: fixed;
  right: 165px;
  top: 120px;
  z-index: 2;
  pointer-events: all;

  // https://www.quirksmode.org/dom/inputfile.html
  .inputGroup {
    position: relative;

    input {
      width: 140px;
      height: 25px;
      z-index: 2;
      opacity: 0;
    }
    button {
      position: absolute;
      right: 0;
    }
  }
}
