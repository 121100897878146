.apotome {
  .looper {
    .button {
      flex-flow: column;

      span {
        display: block;
      }
    }
  }
}
