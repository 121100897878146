@import url("https://use.typekit.net/ugu1iba.css");

body {
  font-kerning: normal;
  font-variant-ligatures: common-ligatures contextual;
  font-feature-settings: "kern", "liga", "clig", "calt";
  -webkit-font-smoothing: antialiased;
  font-family: urw-din, sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: white;

  h1,
  h2,
  h3,
  h4 {
    font-weight: 500;
  }

  h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 1;
    margin: 0;
    padding: 0;
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }

  .introText {
    font-size: 13px;
    margin: 5px 0 0 0;
    color: rgba(white, 0.75);
  }
}
