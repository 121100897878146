$backgroundColor: #111111;
$primaryElementBackgroundColor: #222;
$secondaryElementBackgroundColor: #333;
$selectedSecondaryElementBackgroundColor: #555;

$textColor: #fff;
$textHighlightColor: rgb(72, 137, 137);

$borderColor: rgba(white, 0.25);
$borderColorSecondary: rgba(white, 0.125);

$returnTrackWidth: 130px;
$headerHeight: 121px;

$sidebarCalloutsWidth: 50px;
$sessionManagerWidth: 340px;
$tuningSnapshotsManagerWidth: 340px;
