.euclideanVisualizer {
  width: 100px;
  height: 100px;

  .beat {
    fill: rgba(white, 0.2);
    stroke: rgba(white, 0);
    stroke-width: 3;
    &.on {
      fill: #bde1f4;
    }
    &.current {
      stroke: white;
    }
  }
}
