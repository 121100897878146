.tuningSystemDatabaseEntryWrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(black, 0.7);

  z-index: 3;

  pointer-events: none;
  opacity: 0;
  transition: opacity 0.4s;
  &.isOpen {
    pointer-events: initial;
    opacity: 1;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .tuningSystemDatabaseEntry {
    width: 500px;
    position: relative;
    background-color: #333;
    border-radius: 3px;
    overflow: hidden;

    padding: 20px;

    h3 {
      margin: 10px 0;
    }
    &--field {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 3px 0;

      input,
      textarea {
        width: 300px;
      }
      textarea {
        height: 70px;
        resize: none;
      }
    }
    &--actions {
      display: flex;
      flex-direction: row;
    }
    select {
      appearance: menulist;
      background: white;
      height: auto;
      width: auto;
      color: black;
    }
    .button {
      flex: 1;
      font-size: 14px;
      box-shadow: none;
      margin-top: 40px;
      &:disabled {
        background-color: gray;
        color: darkgray;
      }
    }
  }
}
