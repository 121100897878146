@import "./variables.scss";
@import "./typography.scss";
@import "./buttons.scss";
@import "./inputs.scss";

*,
*:before,
*:after {
  box-sizing: border-box;
}

.apotome {
  width: 100%;
  height: 100%;
  background-color: $backgroundColor;
  overflow: hidden;
  display: grid;
  grid-template-areas:
    "header header"
    "main aside";
  grid-template-rows: auto 1fr;
  grid-template-columns: 1fr auto;

  .header {
    grid-area: header;
    z-index: 3;
  }

  .main {
    grid-area: main;
    z-index: 1;
    overflow: auto;
    background: #222;

    .tracks {
      display: grid;
      grid-auto-flow: column;
      justify-content: start;
      gap: 10px;
      padding: 10px;
    }
  }

  .aside {
    grid-area: aside;
    z-index: 2;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: auto auto;
    overflow-y: auto;

    .sidebarCallouts {
      width: $sidebarCalloutsWidth;
      padding: 15px;

      display: none;
      &.isVisible {
        display: block;
      }

      &--buttons {
        display: flex;
        transform-origin: 0 0;
        transform: translateX(22px) rotate(90deg);
      }
      button {
        flex-shrink: 0;
        margin-right: 10px;
      }
    }
  }

  .react-draggable {
    z-index: 3;
  }
  .pluginGUIBar {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 20px;
    background-color: black;
    overflow: hidden;
    cursor: move;

    .closePluginGUIBar {
      position: absolute;
      right: 10px;
      top: 0;
      border: 0;
      padding: 0;
      font-size: 18px;
    }
  }

  .pluginGUIContent {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    bottom: 0;
  }

  // Leimma element adjustments

  .chooseTuningSystemDialog,
  .calibrateMIDIOutputDialog {
    background-color: $primaryElementBackgroundColor;
    li.isSelected {
      background-color: #555;
    }
  }

  .refPitchNote {
    color: $textColor;
    border: 1px solid $textColor;
    &:disabled {
      border-color: rgba($borderColor, 0.3);
      color: rgba($textColor, 0.3);
    }
  }
  .refPitchInput {
    .freqNumberInput {
      color: $textColor;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      font-size: 12px;
      padding-bottom: 4px;
      border-bottom: 1px solid $borderColor;
    }
    .hzLabel {
      color: white;
      font-weight: 500;
    }
    &.isDisabled {
      .freqNumberInput {
        border-color: rgba($borderColor, 0.3);
        color: rgba($textColor, 0.3);
      }
      .hzLabel {
        color: rgba($borderColor, 0.3);
      }
    }
  }

  &--addTrack {
    padding: 15px 15px 10px 10px;
  }

  .button--addTrack {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    padding: 0;
    background: white;
    border: 1px solid white;

    .icon {
      fill: black;
    }
  }

  .tuningSnapshotsManager {
    width: $tuningSnapshotsManagerWidth;
    background-color: #111;
    border-left: 1px solid rgba(white, 0.1);

    display: none;

    .closeButton {
      position: absolute;
      top: 25px;
      right: 15px;
    }

    &--snapshot {
      background-color: #090909;
      &.isCurrent {
        outline: 1px solid $borderColor;
      }
    }

    &--notSaved {
      display: none;
    }
  }
}

.apotome.tuningSnapshotsManagerOpen .tuningSnapshotsManager {
  display: block;
}
