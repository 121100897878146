@import "./variables.scss";

.apotome {
  .melodicIntervalToggle {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 10px;

    .toggleButton {
      margin: 2px;
    }

    &--label {
      margin-top: 2px;
      padding: 3px;
      border-radius: 2px;

      &.isPlaying {
        background-color: rgba(#bde1f4, 0.5);
      }
    }
  }
}
