@import "./variables.scss";

.apotome {
  .loadingSplashWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.7);

    z-index: 3;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s;
    &.isOpen {
      pointer-events: initial;
      opacity: 1;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .loadingSplash {
      .loadingAnimation {
        width: 40px;
        height: auto;
      }
    }
  }
}
