@import "./variables.scss";

.apotome {
  .weightsControl {
    width: 100%;

    &.isDisabled {
      pointer-events: none;
      opacity: 0.3;
    }
    &--sliders {
      width: 100%;

      svg {
        background-color: rbga(white, 0.5) !important;
      }
    }
    &--labels {
      display: flex;
    }
    &--label {
      flex: 1 1 0;
      flex-basis: 0;
      text-align: center;
      overflow: hidden;
      font-size: 10px;
      margin: 3px 0;
      padding: 3px 0;
      border-radius: 2px;

      &.isRatio {
        display: flex;
        flex-direction: column;
        align-items: center;
        :first-child {
          border-bottom: 1px solid white;
        }
      }

      &.isPlaying {
        background-color: rgba(#bde1f4, 0.5);
      }
    }
  }
}
