.octaveDivisionBand {
  &--interval {
    fill: #333;
    stroke: none;
    &.isPassive {
      pointer-events: none;
    }
  }
  &--division {
    fill: rgba(white, 0);
    &.isMoveable {
      cursor: col-resize;
    }
    &.isPassive {
      pointer-events: none;
    }
  }
  &--divisionLabel {
    font-size: 12px;
    font-weight: 500;
    fill: white;
    &.isPassive {
      pointer-events: none;
    }
  }
}
