.onScreenKeyboardContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background-color: #eee;
  z-index: 3;

  transform: translateY(100%);
  transition: transform 0.15s ease;
  &.isOpen {
    transform: translateY(0);
  }

  .onScreenKeyboardCallout {
    position: absolute;
    top: 0;
    left: 50%;
    width: 150px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translate(-50%, -100%);

    background-color: #eee;
    border: none;
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
    font-family: urw-din, sans-serif;
    font-weight: 600;
    color: black;
    outline: none;
    cursor: pointer;
  }

  .onScreenKeyboard {
    position: absolute;
    left: 0;
    width: 100%;
    margin: 10px;
    display: flex;
    margin-bottom: 50px;
    justify-content: center;

    &--step {
      width: 50px;
      height: 60px;
      padding: 5px 0;
      margin: 0 2px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: white;
      border: none;
      border-radius: 3px;
      box-shadow: 0 2px 5px 0 rgba(black, 0.2);
      outline: none;
      font-family: urw-din, sans-serif;
      font-weight: 500;
      cursor: pointer;

      &--qwertyMapping {
        font-size: 10px;
        font-style: italic;
      }
      &--cents {
        font-size: 14px;
        font-weight: 600;
      }

      &--solmization {
        font-size: 10px;
      }

      &.isPlaying {
        box-shadow: 0 2px 0 0 rgba(black, 0.2);
        background: red;
      }

      &.isAccidental {
        background-color: black;
        color: white;
        &.isPlaying {
          box-shadow: 0 2px 0 0 rgba(black, 0.2);
          background: red;
        }
      }

      &:not(.isAccidental) {
        transform: translateY(20px);
      }
    }
  }
  .onScreenKeyboardOctave {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-family: urw-din, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: black;

    &--change {
      margin: 0 5px;
      background: none;
      border: 1px solid #111;
      border-radius: 3px;
      outline: none;
      &.isDisabled {
        opacity: 0;
        pointer-events: none;
      }
    }
    &--qwertyMapping {
      font-size: 12px;
      font-style: italic;
    }
  }
}
