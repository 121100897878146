// Product specific customisations in Apotome.scss for apotome, and TuningSnapshots.scss for Leimma

.tuningSnapshotsManager {
  position: relative;
  overflow-y: auto;

  &--content {
    padding: 15px;
  }

  &--notAuthenticated {
    padding: 10px;
  }

  &--snapshot {
    position: relative;
    padding: 15px;
    border-radius: 5px;
    margin: 0 0 15px 0;
  }

  &--snapshotName {
    margin: 0 0 5px 0;
    font-size: 12px;
    font-weight: 500;
  }

  &--snapshotTuning {
    margin: 0 0 5px 0;
    font-size: 10px;
    font-weight: 500;
  }

  &--snapshotActions {
    margin-top: 10px;
    button {
      margin-right: 5px;
    }
  }

  &--newSnapshot {
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    > * {
      margin-top: 10px;
    }
    button {
      width: 100px;
    }
  }

  &--notSaved {
    font-size: 12px;
  }
}
