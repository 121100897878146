@import "../variables.scss";

.apotome {
  .submitConfirmationDialogWrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(black, 0.7);

    z-index: 3;

    pointer-events: none;
    opacity: 0;
    transition: opacity 0.4s;
    &.isOpen {
      pointer-events: initial;
      opacity: 1;
    }

    display: flex;
    align-items: center;
    justify-content: center;

    .submitConfirmationDialog {
      width: 550px;
      height: 300px;
      position: relative;
      background-color: $primaryElementBackgroundColor;
      color: $textColor;

      padding: 20px;

      p {
        font-size: 16px;
        line-height: 1.5;
        a {
          color: white;
        }
      }
      &--actions {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          margin-left: 10px;
          padding: 0 20px;
        }
      }
    }
  }
}
