.panControl {
  &.isDisabled {
    pointer-events: none;
    opacity: 0.3;
  }
  &--pan {
    margin: 0;
  }
  &--label,
  &--valueLabel {
    font-size: 10px;
    text-align: center;
  }
  &--label {
    margin-bottom: 14px;
  }
  &--valueLabel {
    margin-top: 14px;
  }
}
