@import "../variables.scss";

.sessionEditor {
  h2 {
    padding: 10px;
  }
  h3 {
    padding: 0 10px;
  }
  .backToMain {
    margin-right: 5px;
  }

  &--actions {
    margin-bottom: 10px;
    padding: 10px;
    display: flex;
    .button {
      flex: 1;
      margin-right: 5px;
      margin-top: 5px;
      padding: 10px;
    }
  }

  &--attributes {
    padding: 10px;
  }
  &--attribute {
    display: block;
    margin-bottom: 20px;
    font-size: 12px;
    input {
      display: block;
      margin-top: 2px;
      width: 100%;
    }
  }

  &--section {
    margin-bottom: 5px;
    padding: 10px;
    background-color: $secondaryElementBackgroundColor;
    font-size: 14px;
    h4 {
      margin: 0 0 10px 0;
    }
  }
  &--sectionName {
    display: block;
    margin-bottom: 20px;
    font-size: 14px;
    input {
      display: block;
      margin-top: 2px;
      width: 100%;
    }
  }
  &--sectionTiming {
    display: flex;
    align-items: flex-end;
    height: 20px;
    line-height: 20px;
    > * {
      margin: 0 2px;
    }
  }
  &--sectionActions {
    margin-top: 10px;
    button {
      margin-right: 5px;
      margin-top: 5px;
    }
  }
  &--timingRange {
    display: flex;
    flex-direction: column;
    .input {
      width: 70px;
    }
  }
  &--newSection {
    margin: 10px;
    display: block;
    width: calc(100% - 20px);
  }

  .formatLabel {
    font-size: 9px;
  }
}
