@import "./variables.scss";

.apotome {
  .scaleStrip {
    margin-top: 5px;
    cursor: pointer;

    &--strip {
      width: 100%;
      position: relative;
      height: 50px;
    }
    &--stripSegment {
      position: absolute;
      top: 3px;
      height: 20px;
      background-color: $secondaryElementBackgroundColor;

      &.isMapped {
        background-color: $selectedSecondaryElementBackgroundColor;
      }
    }
    &--tonicMarker {
      position: absolute;
      top: 0;
      height: 26px;
      width: 1px;
      transform: translateX(-1px);
      background-color: white;
    }
    &--stripSegmentLabel {
      position: absolute;
      top: 24px;
      font-size: 10px;
      font-weight: 500;
      padding-right: 3px;
      transform: rotate(270deg) translate(-100%, -50%);
      transform-origin: 0 0;
    }
  }
}
