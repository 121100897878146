.octaveDivisionStringsView {
  position: fixed;
  left: 0;
  width: 100%;
  top: 400px;
  bottom: 160px;
  overflow-y: auto;

  &--openStringTunings {
    position: relative;

    .pitchInput,
    button {
      position: absolute;
      left: 10px;
      top: 0;
      width: 100px;
    }
  }

  &--rulerSwitcher {
    position: absolute;
    left: 10px;
    width: 300px;
    display: flex;
    align-items: center;
    label,
    select {
      flex: 1;
    }
  }

  &--stringActions {
    position: relative;

    button {
      position: absolute;
      right: 10px;
      top: 0;
      width: 100px;
    }
  }
  &--canvas {
    position: absolute;
    left: 150px;
    width: calc(100vw - 300px);

    .octaveDivisionStringsView--string {
      stroke: black;
      stroke-width: 1;
    }
    .octaveDivisionStringsView--stringStop {
      stroke: black;
      stroke-width: 2;
    }
    .octaveDivisionStringsView--stringStopLabel {
      font-size: 12px;
    }
    .octaveDivisionStringsView--stringEnd {
      fill: black;
    }

    .octaveDivisionStringsView--ruler {
      stroke: #ddd;
      stroke-width: 1;
    }
  }
}
